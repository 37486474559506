import React from 'react'

import { Typography } from '@material-ui/core'

const OwnerField = ({ record }) => {
  return <Typography variant="body2">{`${record.owner}`}</Typography>
}

OwnerField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'Owner',
}

export default OwnerField
