import React from 'react'

import { TextField } from 'react-admin'
import { ReferenceField } from './index'

const CenterField = ({children, ...props}) => (
  <ReferenceField {...props}>
    {children || <TextField source="name" />}
  </ReferenceField>
)

CenterField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: "Center",
  source: "center.id",
  reference: "Center",
  basePath: "Center",
}

export default CenterField
