import React from 'react'

import {
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin'

const CenterInput = ({ multiple, ...props }) => {
  const W = multiple ? ReferenceArrayInput : ReferenceInput
  const C = multiple ? SelectArrayInput : SelectInput

  return (
    <W {...props}>
      <C optionText="name" />
    </W>
  )
}

CenterInput.defaultProps = {
  addLabel: true,
  reference: 'Center',
  label: 'Center',
  source: 'center',
  multiple: false,
}

export default CenterInput
