import React from 'react'
import { SelectInput } from 'react-admin'

import {choises} from '../fields/TypeField'

const TypeInput = props => <SelectInput choices={choises} {...props}  />

TypeInput.defaultProps = {
  label: 'Type',
  source: 'type',
}

export default TypeInput
