import React from 'react'
import moment from 'moment-timezone'

import {
  useInput,
} from 'react-admin'

import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"

const CalendarInput = props => {
  const {input: {value, onChange}} = useInput(props)

  const selectedDays = value && typeof value === 'object' ? value.map(v => new Date(v)) : []

  const onDayClick = (day, { selected, disabled }, ) => {
    if(!disabled){
      if (selected) {
        onChange(selectedDays.filter(v => v.toDateString() !==day.toDateString()))
      } else{
        onChange([
          ...value,
          moment(day).tz('Europe/Madrid').toISOString()
        ])
      }
    }
  }

  return (
    <DayPicker
      firstDayOfWeek={1}
      numberOfMonths={3}
      selectedDays={selectedDays}
      disabledDays={[{ before: new Date() }]}
      onDayClick={onDayClick}
    />
  )
}

CalendarInput.defaultProps = {
  addLabel: true,
  label: 'Select the days of availability of the space:',
  source: 'days',
  fullWidth: true,
}

export default CalendarInput
