import React from 'react'
import { useSelector } from 'react-redux'
import { MenuItemLink, getResources, usePermissions } from 'react-admin'
import ConfigIcon from '@material-ui/icons/Settings'

export default ({ onMenuClick, dense }) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const { permissions } = usePermissions()

  const MenuItem = ({ resource }) =>  (
    <MenuItemLink
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) ||
        resource.name
      }
      leftIcon={
        resource.icon ? <resource.icon /> : null
      }
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  )

  return (
    <div>
      {resources.map(resource => {

        if (!resource.hasList) return null

        if (!resource.icon){
          return null
        }

        return <MenuItem key={resource.name} resource={resource} />
      },
      )}
      {permissions?.includes('ROLE_ADMIN')
        ? (
          <MenuItemLink
            to={`/Config/edit`}
            primaryText="Configuration"
            leftIcon={<ConfigIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )
        : null}
    </div>
  )
}
