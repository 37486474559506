import React from 'react'

import { LocationField } from './index'

const PlaceField = ({record, children}) => (
    children || <LocationField record={record} />
)

PlaceField.defaultProps = {
  addLabel: true,
  sortable: false,
  source: "place",
  reference: "Place",
  basePath: "Place",
}

export default PlaceField
