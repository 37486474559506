import React from 'react'
import {
  List,
  Create,
  Datagrid,
  Filter,
  TextInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TopToolbar,
  ListButton,
  ReferenceArrayInput,
  SelectArrayInput,
  usePermissions,
  downloadCSV,
  BooleanField
} from 'react-admin'

import jsonExport from 'jsonexport/dist'

import { TypeField, SizeField, CenterField, LocationField, OwnerField } from '../fields'
import { TypeInput, CenterInput, SizeInput, CalendarInput } from '../inputs'

import { BookingsGridEdit } from "./booking"

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TypeInput />
    <CenterInput />
  </Filter>
)

const exporter = (places, fetchRelatedRecords) => {
  fetchRelatedRecords(places, 'centerId', 'Center').then(centers => {
    const placesForExport = places.map(post => {
      const { centerId, type, size, place, floor, owner, availability, } = post
      let sizeName = 'Small'
      switch (size) {
        default:
        case 1:
          sizeName = 'Small'
          break;
        case 2:
          sizeName = 'medium'
          break;
        case 3:
          sizeName = 'medium'
          break;
      }
      return {
        center: centers[centerId].name || '', type, size: sizeName, place, floor, owner, availability,
      }
    });
    jsonExport(placesForExport, {
        headers: ['center', 'type', 'size', 'place', 'floor', 'owner', 'availability'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'spaces'); // download as 'posts.csv` file
    })
  })
}

export const ListView = props => (
  <List
    {...props}
    title="Spaces"
    filters={<Filters />}
    bulkActionButtons={false}
    exporter={exporter}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <LocationField />
      <CenterField />
      <TypeField />
      <SizeField />
      <OwnerField />
      <BooleanField source="availability" sortable={false}/>
    </Datagrid>
  </List>
)

const PlaceEditActions = ({basePath}) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

const PlaceEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const EditView = props => {
  const { permissions } = usePermissions()

  if(permissions === undefined) return null

  return (
    <Edit title="Spaces" actions={<PlaceEditActions />} {...props}>
      <SimpleForm toolbar={<PlaceEditToolbar />}>
        {permissions.includes('ROLE_ASSISTANT') && <LocationField />}
        {permissions.includes('ROLE_ADMIN') && <TextInput source="place" />}
        {permissions.includes('ROLE_ADMIN') && <TextInput source="floor" />}
        <CenterField />
        {permissions.includes('ROLE_ASSISTANT') && <TypeField />}
        {permissions.includes('ROLE_ADMIN') && <TypeInput />}
        {permissions.includes('ROLE_ASSISTANT') && <SizeField />}
        {permissions.includes('ROLE_ADMIN') && <SizeInput />}
        {permissions.includes('ROLE_ASSISTANT') && <OwnerField />}
        {permissions.includes('ROLE_ADMIN') && <TextInput source="owner" />}
        {permissions.includes('ROLE_ADMIN') && (
          <ReferenceArrayInput label="Assistants" source="userIds" reference="User" perPage={100} filter={{role: 'ROLE_ASSISTANT'}} sort={{field: 'email', order: 'ASC'}}>
            <SelectArrayInput optionText="email" />
          </ReferenceArrayInput>
        )}
        <CalendarInput />
        <BookingsGridEdit />
      </SimpleForm>
    </Edit>
  )
}

export const CreateView = props => (
  <Create title="Spaces" {...props}>
    <SimpleForm>
      <TextInput source="place" />
      <TextInput source="floor" />
      <CenterInput />
      <TypeInput />
      <SizeInput />
      <TextInput source="owner" />
    </SimpleForm>
  </Create>
)

export {default as Icon} from '@material-ui/icons/Place'
