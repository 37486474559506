import React from 'react'
import PropTypes from 'prop-types'

import { capitalize } from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography } from '@material-ui/core'

import { SelectInput, NumberInput, minValue, maxValue, required } from 'react-admin'

const useStylesCard = () =>
  makeStyles({
    root: {
      width: 500,
      margin: '0.5em',
    },
  })()

const weekdays = [
  { id: 1, name: 'Monday'},
  { id: 2, name: 'Tuesday'},
  { id: 3, name: 'Wednesday'},
  { id: 4, name: 'Thusday'},
  { id: 5, name: 'Friday'},
  { id: 6, name: 'Saturday'},
  { id: 0, name: 'Sunday'},
]

const CheckinInput = ({source, ...props}) => {
  const title = capitalize(source.split('_')[1])
  const info = props.record[source]
  const classesCard = useStylesCard()
  return (
    <Card classes={classesCard}>
      <CardContent>
        <Typography variant="h6" component="div">
          {title} check-in
        </Typography>
        <Typography variant="caption" component="div">
          Actual value: Every {weekdays.find(w => w.id === info.weekday)?.name.toLowerCase()} at {info.hour}:00
        </Typography>
        <SelectInput source={`${source}.weekday`} label="Weekday" choices={weekdays} required {...props} />
        <NumberInput source={`${source}.hour`} label="Hour" step={1} validate={[required(), minValue(0), maxValue(23)]} {...props} />
      </CardContent>
    </Card>
  )
}

CheckinInput.propTypes = {
  source: PropTypes.string.isRequired,
}


export default CheckinInput
