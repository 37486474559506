import React from 'react'

import { Typography } from '@material-ui/core'

import get from 'lodash/get'

const DateField = ({ record, source, addLabel, sortable, ...props }) => {
  const value = get(record, source)
  if(value === undefined) return null

  const dateObj = new Date(value)
  const month = `0${dateObj.getMonth() + 1}`.slice(-2)
  const date = `0${dateObj.getDate()}`.slice(-2)
  const year = dateObj.getFullYear()

  return <Typography variant="body2" {...props}>{`${date}/${month}/${year}`}</Typography>
}

DateField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'Date',
  source: 'date',
}

export default DateField
