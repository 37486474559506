import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import buildGraphQLProvider, {
  buildQuery,
} from 'ra-data-graphql-simple'
import { gql } from 'apollo-boost'

const myBuildQuery = introspection => (
  fetchType,
  resource,
  params,
) => {
  const builtQuery = buildQuery(introspection)(
    fetchType,
    resource,
    params,
  )

  if (resource === 'Place' && fetchType === 'GET_ONE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query Place($id: ID!) {
          data: Place(id: $id) {
            id
            place
            floor
            owner
            type
            size
            centerId
            userIds
            center {
              id
              name
            }
            days
            bookings {
              id
              state
              day
              licensePlate
              user {
                id
                name
                surname
                email
              }
            }
          }
        }
      `,
    }
  }

  if (resource === 'Place' && fetchType === 'UPDATE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        mutation Place($id: ID!, $days: [Date], $userIds: [ID], $floor: String, $owner: String, $place: String, $size: Int, $type: String) {
          data: updatePlace(id: $id, days: $days, userIds: $userIds, floor: $floor, owner: $owner, place: $place, size: $size, type: $type) {
            id
            place
            floor
            type
          }
        }
      `,
    }
  }

  if (resource === 'Booking' && fetchType === 'GET_LIST') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        query allBookings(
          $page: Int
          $perPage: Int
          $sortField: String
          $sortOrder: String
          $filter: BookingFilter
        ) {
          items: allBookings(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            id
            place {
              centerId
              type
              place
              floor
            }
            day
            state
            licensePlate
          }
          total: _allBookingsMeta(
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortOrder: $sortOrder
            filter: $filter
          ) {
            count
          }
        }
      `,
    }
  }

  if (resource === 'Booking' && fetchType === 'CREATE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
        mutation Place($center: String!, $type: PlaceType, $size: Int!, $licensePlate: String! $days: [Date]!) {
          data: createBooking(centerId: $center, type: $type, licensePlate: $licensePlate, days: $days, size: $size) {
            id
            day
            state
            licensePlate
          }
        }
      `,
    }
  }

  return builtQuery
}

export default callback => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, response, operation, forward }) => {
  response.errors = null
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      switch (err.code) {
        default:
          console.log('error -----> ', err)
          break
        case 401:
          localStorage.removeItem('token')
          operation.setContext({
            headers: {},
          })
      }
    })
    return forward(operation)
  }
})

  const client = new ApolloClient({
    link: ApolloLink.from([
      authLink,
      errorLink,
      new HttpLink({
        uri: `${process.env.REACT_APP_API_URL}/graphql`,
      }),
    ]),
    cache: new InMemoryCache(),
    name: 'parkings-client',
    connectToDevTools: true,
  })
  buildGraphQLProvider({ client, buildQuery: myBuildQuery })
    .then(callback)
    .catch(callback)
}
