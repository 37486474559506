import React, {useState, useCallback} from 'react'

import {
  useNotify,
  useRedirect,
  useRefresh,
  useDelete,
  Confirm,
  CRUD_DELETE,
} from 'react-admin'

import Button from '@material-ui/core/Button'

import { red } from '@material-ui/core/colors'

export default ({ view, record, onClick, redirect: redirectTo = 'list', basePath }) => {
  const [deleteOne, { loading }] = useDelete('Booking', record.id, {view}, {
        action: CRUD_DELETE,
        onSuccess: () => {
            notify('ra.notification.deleted', 'info', { smart_count: 1 })
            redirect(redirectTo, basePath)
            refresh()
        },
        onFailure: error => {
            notify(
                typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error',
                'warning'
            )
            setOpen(false)
        },
        undoable: false,
    })
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()

  const handleClick = e => {
    setOpen(true)
    e.stopPropagation()
  }
  const handleConfirm = useCallback(
      event => {
        deleteOne()
        if (typeof onClick === 'function') {
            onClick(event)
        }
      },
      [deleteOne, onClick]
  )
  const handleDialogClose = e => {
    setOpen(false)
    e.stopPropagation()
  }

  const title = () => {
    switch(view){
      case 'booking': return 'Cancel'
      case 'place': return 'Reject'
      default: return null
    }
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={loading}
        style={{ color: red[500], }}
      >
        {title()}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={`${title()} booking`}
        content="Are you sure?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
