import React from 'react'

import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EvStation, LocalGasStation } from '@material-ui/icons'

import { blueGrey } from '@material-ui/core/colors'

import get from 'lodash/get'

const useStyles = makeStyles({
  chip: {
    background: blueGrey[500],
    color: 'white'
  },
})

export const choises = [
  { id: 1, name: 'Small', icon: LocalGasStation},
  { id: 2, name: 'Medium', icon: EvStation},
  { id: 3, name: 'Large', icon: EvStation},
]

const SizeField = ({ record, source }) => {
  const classes = useStyles()
  const choise = choises.find(s => s.id === get(record, source))

  if (choise) return (
    <Chip
      className={classes.chip}
      label={choise.name}
    />
  )

  return null
}

SizeField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'Size',
  source: 'size',
}

export default SizeField
