import React from 'react'

import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EvStation, LocalGasStation } from '@material-ui/icons'

import { lightBlue, lightGreen } from '@material-ui/core/colors'

import get from 'lodash/get'

const useStyles = makeStyles({
  standard: {
    background: lightBlue[500],
    color: 'white'
  },
  electric: {
    background: lightGreen[500],
    color: 'white'
  },
  icon: {
    color: 'white',
    paddingLeft: 3,
  }
})

export const choises = [
  { id: 'standard', name: 'Standard', icon: LocalGasStation},
  { id: 'electric', name: 'Electric', icon: EvStation},
]

const TypeField = ({ record, source }) => {
  const classes = useStyles()
  const choise = choises.find(s => s.id === get(record, source))

  if (choise) return (
    <Chip
      className={classes[choise.id]}
      label={choise.name}
    />
  )

  return null
}

TypeField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'Type',
  source: 'type',
}

export default TypeField
