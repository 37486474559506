import React from 'react'
import { SelectInput } from 'react-admin'

import {choises} from '../fields/StateField'

const StateInput = props => <SelectInput choices={choises} {...props}  />

StateInput.defaultProps = {
  label: 'State',
  source: 'state',
}

export default StateInput
