import React from 'react'
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
} from 'react-admin'

import {
  CheckinInput
} from '../inputs'

const ConfigToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect="edit" />
  </Toolbar>
)

export const EditView = props => (
  <Edit title="Configuration" {...props}>
    <SimpleForm toolbar={<ConfigToolbar />}>
      <CheckinInput source="checkin_customer" />
      <CheckinInput source="checkin_preference" />
    </SimpleForm>
  </Edit>
)
