import React from 'react'

import { Typography } from '@material-ui/core'

const LocationField = ({ record }) => {
  return <Typography variant="body2">{`${record.floor} - ${record.place}`}</Typography>
}

LocationField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'Location',
}

export default LocationField
