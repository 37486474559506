import decodeJwt from 'jwt-decode'
export default {
  // called when the user attempts to log in
  login: token => {
    localStorage.setItem('token', token)
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status, graphQLErrors }) => {
    if (graphQLErrors[0].code === 401) {
      localStorage.removeItem('token')
      return Promise.reject({ redirectTo: '/login' })
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const token = localStorage.getItem('token')
    const {role} = decodeJwt(token)
    if (role === 'ROLE_ASSISTANT_PREFERENCE') {
      return Promise.resolve(['ROLE_ASSISTANT'])
    }
    return Promise.resolve([role])
  },
}

export const hasPermission = (
  permissions = [],
  Model,
  roles = [],
) => {
  if (permissions == null) return null
  /* eslint-disable-next-line no-param-reassign */
  if (typeof roles === 'string') roles = [roles]
  roles.push('ROLE_ASSISTANT')
  return permissions.filter(x => roles.includes(x)).length > 0
    ? Model
    : null
}
