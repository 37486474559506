import React from 'react'

import { ReferenceField as ReferenceFieldRA } from 'react-admin'


const ReferenceField = props => <ReferenceFieldRA {...props} />

ReferenceField.defaultProps = {
  addLabel: true,
  sortable: false,
  link: false,
}

export default ReferenceField
