import React from 'react'
import {
  List,
  Datagrid,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  TopToolbar,
  ListButton,
  TextField,
  SelectInput,
  TextInput,
} from 'react-admin'

const Filters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput source="role" choices={[
        { id: 'ROLE_CUSTOMER', name: 'ROLE_CUSTOMER' },
        { id: 'ROLE_PREFERENCE', name: 'ROLE_PREFERENCE' },
        { id: 'ROLE_ASSISTANT', name: 'ROLE_ASSISTANT' },
        { id: 'ROLE_ASSISTANT_PREFERENCE', name: 'ROLE_ASSISTANT_PREFERENCE' },
        { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
    ]} />
  </Filter>
)

export const ListView = props => (
  <List
    {...props}
    title="Users"
    filters={<Filters />}
    bulkActionButtons={false}
    exporter={false}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="email" />
      <TextField source="role" />
      <TextField source="lastLicensePlate" />
    </Datagrid>
  </List>
)

const PlaceEditActions = ({basePath}) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

const PlaceEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const EditView = props => (
  <Edit title="Spaces" actions={<PlaceEditActions />} {...props}>
    <SimpleForm toolbar={<PlaceEditToolbar />}>
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="email" />
      <SelectInput source="role" choices={[
          { id: 'ROLE_CUSTOMER', name: 'ROLE_CUSTOMER' },
          { id: 'ROLE_PREFERENCE', name: 'ROLE_PREFERENCE' },
          { id: 'ROLE_ASSISTANT', name: 'ROLE_ASSISTANT' },
          { id: 'ROLE_ASSISTANT_PREFERENCE', name: 'ROLE_ASSISTANT_PREFERENCE' },
          { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
      ]} />
      <TextField source="lastLicensePlate" />
    </SimpleForm>
  </Edit>
)

export {default as Icon} from '@material-ui/icons/Person'
