import React, { useEffect, useState} from 'react'
import { Admin, Resource, Loading } from 'react-admin'


import {authProvider, hasPermission, dataProvider } from './providers/CrowdGraphQL'

import loginPage from './pages/loginPage'

import * as Place from './models/place'
import * as Booking from './models/booking'
import * as User from './models/user'
import * as Config from './models/config'

import layout from './layout'

const App = () => {
  const [provider, setProvider] = useState(null)

  useEffect(
    () => {
      const callback = props => {
        setProvider(() => props)
      }
      dataProvider(callback)
    },
    [],
  )

  if(provider === null){
    return (
      <Loading
        loadingPrimary="Loading"
        loadingSecondary="please, wait for a while"
      />
    )
  }

  const appParameters = {authProvider, dataProvider: provider, loginPage, layout}

  return (
    <Admin {...appParameters}>
      {permissions => [
        <Resource name="Booking" list={Booking.ListView} create={Booking.CreateView} icon={Booking.Icon} />,
        hasPermission(permissions, <Resource name="Place" options={{label: 'Spaces'}} list={Place.ListView} edit={Place.EditView} icon={Place.Icon} />, 'ROLE_ASSISTANT'),
        hasPermission(permissions, <Resource name="Place" options={{label: 'Spaces'}} list={Place.ListView} create={Place.CreateView} edit={Place.EditView} icon={Place.Icon} />, 'ROLE_ADMIN'),
        hasPermission(permissions, <Resource name="User" options={{label: 'Users'}} list={User.ListView} edit={User.EditView} icon={User.Icon} />, 'ROLE_ADMIN'),
        hasPermission(permissions, <Resource name="User" />, 'ROLE_ASSISTANT'),
        hasPermission(permissions, <Resource name="Config" edit={Config.EditView} />, 'ROLE_ADMIN'),
        <Resource name="Center" />,
    ]}
    </Admin>
  )
}

export default App
