import React, { useState, useEffect, useCallback } from 'react'
import { createApolloFetch } from 'apollo-fetch'

import {
  useInput,
  FormDataConsumer,
  LinearProgress,
} from 'react-admin'
import { Typography } from '@material-ui/core'

import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

const maxFutureDays = 10

const SelectDayInput = ({ availableDays, ...props }) => {
  const {
    input: { value, onChange },
  } = useInput(props)
  const aDays = availableDays.map(ad => new Date(ad))
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + maxFutureDays)
  const disabledDays = [{ before: new Date() }, { after: maxDate }]

  const actualDate = new Date()
  while (actualDate <= maxDate) {
    if (
      !aDays.some(
        ad => ad.toDateString() === actualDate.toDateString(),
      )
    ) {
      disabledDays.push(new Date(actualDate.getTime()))
    }
    actualDate.setDate(actualDate.getDate() + 1)
  }

  const selectedDays =
    value && typeof value === 'object'
      ? value.map(v => new Date(v))
      : []

  const onDayClick = (day, { selected, disabled }) => {
    if (!disabled) {
      if (selected) {
        onChange(
          selectedDays.filter(
            v => v.toDateString() !== day.toDateString(),
          ),
        )
      } else {
        onChange([...value, day.toISOString()])
      }
    }
  }

  return (
    <DayPicker
      firstDayOfWeek={1}
      numberOfMonths={1}
      disabledDays={disabledDays}
      selectedDays={selectedDays}
      onDayClick={onDayClick}
    />
  )
}

const SelectDayInputProvider = ({ center, type, size, ...props }) => {
  const [availableDays, setAvailableDays] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = createApolloFetch({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  })

  fetch.use(({ request, options }, next) => {
    if (!options.headers) {
      options.headers = {}
    }
    const token = localStorage.getItem('token')
    options.headers['Authorization'] = `Bearer ${token}`

    next()
  })

  const getAvailableDays = useCallback(async () => {
    try {
      const { data : { getBookingSlots: { days } } } = await fetch({
        query:` query Days($center: Int!, $type: PlaceType, $size: Int) {
          getBookingSlots(centerId: $center, type: $type, size: $size) {
            days
          }
        }`,
        variables: { center: parseInt(center), type, size }
      })
      setLoading(false)
      setAvailableDays(days)
    } catch (error) {
      setLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, type, size])

  useEffect(
    () => {
      setAvailableDays(null)
      if (center && type && size) {
        setLoading(true)
        getAvailableDays()
      }
    },
    [center, type, size, getAvailableDays],
  )

  if (loading) return <LinearProgress />
  if (availableDays === null)
    return (
      <Typography variant="body1">
        Please select center, type & size
      </Typography>
    )

  return <SelectDayInput {...props} availableDays={availableDays} />
}

const SelectDayInputWrapper = props => (
  <FormDataConsumer>
    {({ formData: { center, type, size } }) => (
      <SelectDayInputProvider
        {...props}
        center={center}
        type={type}
        size={size}
      />
    )}
  </FormDataConsumer>
)

SelectDayInputWrapper.defaultProps = {
  addLabel: true,
  label: 'Select the days you want to book:',
  source: 'days',
  fullWidth: true,
  availableDays: [],
}

export default SelectDayInputWrapper
