import React from 'react'

const styleEmail = {color: 'gray', fontSize: '.8em', marginLeft: '5px'}

const FullnameField = ({ record = {} }) => (
  <span>
    {`${record.name} ${record.surname}`}
    <span style={styleEmail}>
      { `<${record.email}>`}
    </span>
  </span>
)

FullnameField.defaultProps = { label: 'Name' }

export default FullnameField
