import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Card, CardContent, Grid, Divider } from '@material-ui/core'

import {
  List,
  Create,
  SimpleForm,
  required,
  TextInput,
  TextField,
} from 'react-admin'

import {
  UserField,
  DateField,
  StateField,
  PlaceField,
  CenterField,
  TypeField,
} from '../fields'
import { CenterInput, TypeInput, SizeInput, SelectDayInput } from '../inputs'
import DeleteBooking from '../buttons/DeleteBooking'

const useStylesCard = () =>
  makeStyles({
    root: {
      width: 300,
      margin: '0.5em',
      display: 'inline-block',
      verticalAlign: 'top',
    },
  })()

const useStylesCardContent = () =>
  makeStyles({
    root: {
      padding: '0 16px 10px !important',
    },
  })()

const useStylesHeader = () =>
  makeStyles({
    root: {
      minHeight: 50,
    },
  })()

const useStylesDivider = () =>
  makeStyles({
    root: {
      marginBottom: 10,
    },
  })()

const BookingCard = ({ booking, view, ...props }) => {
  const classesCard = useStylesCard()
  const classesCardContent = useStylesCardContent()
  const classesHeader = useStylesHeader()
  const classesDivider = useStylesDivider()

  return (
    <Card key={booking.id} classes={classesCard}>
      <CardContent classes={classesCardContent}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          classes={classesHeader}
        >
          <Grid item>
            <DateField
              record={booking}
              source="day"
              style={{ display: 'inline' }}
            />
            {' - '}
            <StateField
              style={{ display: 'inline' }}
              source="state"
              record={booking}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ fontWeight: 500 }}
              source="licensePlate"
              record={booking}
            />
          </Grid>
        </Grid>
        <Divider classes={classesDivider} />
        {(view === 'booking') && (
          <Grid
            container
            style={{minHeight: 50}}
            justify="space-between"
            alignItems="center"
          >
            {booking.place?.place && (
              <>
                <Grid item>
                  <CenterField record={booking} source="place.centerId" style={{ fontWeight: 'bold' }} />
                </Grid>
                <Grid item>
                  <PlaceField record={booking.place} />
                </Grid>
                <Grid item>
                  <PlaceField record={booking.place}>
                    <TypeField record={booking.place} />
                  </PlaceField>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {view === 'place' && (
          <Grid
            container
            style={{minHeight: 30}}
          >
            <Grid item>
              <UserField record={booking.user} />
            </Grid>
          </Grid>
        )}
        <Divider classes={classesDivider} />
        <Grid
          container
          style={{minHeight: 36}}
        >
          {booking.state === 'ACTIVE' && (
            <Grid item>
              <DeleteBooking view={view} record={booking} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export const BookingsGridEdit = ({ source, record, view }) =>
  record[source] ? (
    <div style={{ margin: '0.5em -0.5em 1em' }}>
      <BookingGridBlock bookings={record[source]} view={view} />
    </div>
  ) : null
BookingsGridEdit.defaultProps = {
  addLabel: true,
  label: 'Active Bookings:',
  source: 'bookings',
  view: 'place',
}

const BookingsGridList = ({ ids, data, view }) => (
  <div style={{ margin: '.5em' }}>
    <BookingGridBlock
      bookings={ids.map(id => data[id])}
      view={view}
    />
  </div>
)
BookingsGridList.defaultProps = {
  view: 'booking',
}

const BookingGridBlock = ({ bookings, ...props }) =>
  bookings.map(b => <BookingCard key={b.id} booking={b} {...props} />)

export const ListView = props => (
  <List
    title="Bookings"
    exporter={false}
    pagination={null}
    sort={{
      field: 'day',
      order: 'ASC',
    }}
    {...props}
  >
    <BookingsGridList />
  </List>
)

const postDefaultValue = { center: '1', type: 'standard', size: 2 }

export const CreateView = props => (
  <Create title="Bookings" {...props}>
    <SimpleForm initialValues={postDefaultValue}>
      <Grid container spacing={3} style={{minWidth: '100%'}}>
        <Grid item xs={12} sm={6} md={4} style={{display: 'flex', flexDirection: 'column'}}>
          <CenterInput validate={required()} />
          <SizeInput validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{display: 'flex', flexDirection: 'column'}}>
          <TypeInput validate={required()} />
          <TextInput source="licensePlate" label="License Plate" validate={required()} />
        </Grid>
      </Grid>
      <Grid container style={{minWidth: '100%'}}>
        <Grid item xs={12}>
          <SelectDayInput validate={required()} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)

export {
  default as Icon,
} from '@material-ui/icons/ConfirmationNumber'
