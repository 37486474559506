import React from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { red, green } from '@material-ui/core/colors'

import get from 'lodash/get'

const useStyles = makeStyles({
  ACTIVE: { color: green[500] },
  REJECTED: { color: red[500] },
  CANCELLED: { color: red[500] },
})

export const choises = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'REJECTED', name: 'Rejected' },
  { id: 'CANCELLED', name: 'Cancelled' },
]

const StateField = ({ record, sortable, addLabel, basePath, source, ...props }) => {
  const classes = useStyles()

  const choise = choises.find(s => s.id === get(record, source))

  if (choise)
    return (
      <Typography {...props} className={classes[choise.id]} variant="body2">
        {choise.name}
      </Typography>
    )

  return null
}

StateField.defaultProps = {
  addLabel: true,
  sortable: false,
  label: 'State',
  source: 'state',
}

export default StateField
