import React from 'react'

import { FullnameField } from './index'

const UserField = ({record, ...props}) => (
  <FullnameField record={record} />
)

UserField.defaultProps = {
  addLabel: true,
  sortable: false,
  source: "user",
  reference: "User",
  basePath: "User",
}

export default UserField
