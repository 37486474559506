import React from 'react'
import { SelectInput } from 'react-admin'

import {choises} from '../fields/SizeField'

const SizeInput = props => <SelectInput choices={choises} {...props}  />

SizeInput.defaultProps = {
  label: 'Size',
  source: 'size',
}

export default SizeInput
